export const VISIBILITY_FILTERS = {
  ALL: "all",
  COMPLETED: "completed",
  INCOMPLETE: "incomplete"
} as const;

// js version
// export const VISIBILITY_FILTERS = {
//   readonly ALL: "all";
//   readonly COMPLETED: "completed";
//   readonly INCOMPLETE: "incomplete";
// }